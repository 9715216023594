import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';

// import iphone_messages from '../iphone_messages.png';
import iphone_messages from '../screen.png';
import pitch_image from '../homepage_tennis.jpg';
// Icons
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { FaPersonRunning } from "react-icons/fa6";
import { IoPersonSharp } from "react-icons/io5";
import expertise_icon from '../expertise_icon.svg';
import fans_icon from '../fans_icon.svg';
import passion_icon from '../passion_icon.svg';
import pace_icon from '../pace_icon.svg';
import world_icon from '../world_icon.svg';
import exclusive_icon from '../exclusive_icon.svg';
import filter_icon from '../filter_icon.svg';
import direct_icon from '../direct_icon.svg';
import { BsChatTextFill } from "react-icons/bs";
import { FaPaperPlane } from "react-icons/fa";
import { MdVerified } from "react-icons/md";
import { BsPersonVideo2 } from "react-icons/bs";
import { GiCardExchange } from "react-icons/gi";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { BsPersonVideo } from "react-icons/bs";
import { IoMdChatbubbles } from "react-icons/io";

import quote_icon from '../quote-icon.svg';
import quote_crew from '../quote-crew.jpg';
import quote_lifting from '../quote-lifting.jpg';
import quote_baseball from '../quote-baseball.jpg';
import quote_distance from '../quote-distance.jpg';
import quote_crossfit from '../quote-crossfit.jpg';
import quote_burger from '../quote-burger.jpg';
import quote_friends from '../quote-friends.jpg';

import HomeCTASection from '../components/HomeCTASection';

// Additional icons for topics
import { GiWeightLiftingUp, GiRunningShoe, GiMuscleUp, GiHealthNormal } from "react-icons/gi";
import { BsCamera, BsClock } from "react-icons/bs";
import { MdSportsHandball, MdSchool } from "react-icons/md";
import { FaBrain, FaRunning, FaHeartbeat } from "react-icons/fa";
import { IoFitness, IoNutrition } from "react-icons/io5";
import { RiMentalHealthLine } from "react-icons/ri";
import { TbPills } from "react-icons/tb";
import { GrNotes } from "react-icons/gr";
import { TbMeat } from "react-icons/tb";
import { FaPills } from "react-icons/fa6";
import { GiWhistle } from "react-icons/gi";

// Perks Icons
import perks_DM from '../perks_DM.svg';
import perks_chat from '../perks_chat.svg';
import perks_real from '../perks_real.svg';
import perks_history from '../perks_history.svg';
import perks_fast from '../perks_fast.svg';
import perks_nocharge from '../perks_nocharge.svg';


const TopicsScroll = () => {
  const topics = [
    { text: "Lifting Exercise Selection", icon: <GiWeightLiftingUp /> },
    { text: "Technical Video Feedback", icon: <BsCamera /> },
    { text: "Hitting Protein Goals", icon: <TbMeat /> },
    { text: "Injury Recovery", icon: <GiHealthNormal /> },
    { text: "Improving Speed & Agility", icon: <FaRunning /> },
    { text: "Injury Prevention", icon: <FaHeartbeat /> },
    { text: "Workout Programming & Periodization", icon: <GrNotes /> },
    { text: "Mileage Recommendations", icon: <GiRunningShoe /> },
    { text: "Mental Resilience & Confidence", icon: <RiMentalHealthLine /> },
    { text: "Time Management as a Student-Athlete", icon: <BsClock /> },
    { text: "College Recruiting Advice", icon: <MdSchool /> },
    { text: "Supplements for Performance", icon: <FaPills /> },
    { text: "Cross-Training & Injury Alternatives", icon: <GiMuscleUp /> },
    { text: "Sports-Specific Drills & Techniques", icon: <MdSportsHandball /> },
    { text: "Dealing with Setbacks", icon: <FaBrain /> },
    { text: "Getting more game time", icon: <GiWhistle /> },
  ];

  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [isResetting, setIsResetting] = React.useState(false);
  const itemHeight = 60;
  const visibleItems = 5;
  const containerHeight = itemHeight * visibleItems;

  // Create a triple-length array for smooth infinite scroll
  const tripleTopics = [...topics, ...topics, ...topics];

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndex >= topics.length - 1) {
        // When we reach the end of the first set, quickly reset to the second set
        setIsResetting(true);
        setCurrentIndex(0);
        setTimeout(() => setIsResetting(false), 0);
      } else {
        setCurrentIndex(prevIndex => prevIndex + 1);
      }
    }, 1200);

    return () => clearInterval(interval);
  }, [currentIndex]);

  const getHighlightedIndex = (index) => {
    const adjustedIndex = index - 2; // Adjust to make middle item (index 2) the reference
    return adjustedIndex === currentIndex || 
           adjustedIndex === currentIndex + topics.length || 
           adjustedIndex === currentIndex + (2 * topics.length);
  };

  return (
    <div className="topics-container" style={{ height: containerHeight }}>
      <div 
        className="topics-scroll"
        style={{ 
          transform: `translateY(${-currentIndex * itemHeight}px)`,
          transition: isResetting ? 'none' : 'transform 0.3s ease'
        }}
      >
        {tripleTopics.map((topic, index) => (
          <div 
            key={index}
            className={`topic-item ${getHighlightedIndex(index) ? 'highlighted' : ''}`}
            style={{ height: itemHeight }}
          >
            <span className="topic-icon">{topic.icon}</span>
            <span className="topic-text">{topic.text}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const SmoothCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const slides = [
    {
      image: quote_distance,
      alt: "Man running on a trail",
      text: "Should I increase my mileage or pace this early in my marathon training?"
    },
    {
      image: quote_lifting,
      alt: "Woman lifting weight overhead",
      text: "My gym has limited freeweights. What are some squat and deadlift alternatives?"
    },
    {
      image: quote_friends,
      alt: "College students studying",
      text: "How do I know which college coaches I should reach out to for my level"
    },
    {
      image: quote_crew,
      alt: "Overhead view of rowing in shell",
      text: "What supplements do you take? I've been wanting to try creatine, but I'm worried about side effects."
    },
    {
      image: quote_baseball,
      alt: "Man hitting baseball in game",
      text: "My son wants to play in the MLB. Which baseball camps would you recommend?"
    },
    {
      image: quote_crossfit,
      alt: "Man lifting weight overhead",
      text: "As a pro Crossfit athlete, Do you ever have to train through injuries? When do you know to give your body a break?"
    },
    {
      image: quote_burger,
      alt: "Burger on a plate",
      text: "How do you hit your protein goals without gaining a ton of weight or overloading on why powder?"
    }
  ];

  // Create a triple array for infinite scroll effect
  const tripleSlides = [...slides, ...slides, ...slides];

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndex >= slides.length - 1) {
        // Reset to first set without animation
        setCurrentIndex(0);
      } else {
        setCurrentIndex(prev => prev + 1);
      }
    }, 4000);

    return () => clearInterval(interval);
  }, [currentIndex, slides.length]);

  return (
    <div className="smooth-carousel">
      <div 
        className="smooth-carousel-track"
        style={{ 
          transform: `translateX(${-currentIndex * 100}%)`,
          transition: currentIndex === 0 ? 'none' : 'transform 0.8s ease'
        }}
      >
        {tripleSlides.map((slide, index) => (
          <div key={index} className="smooth-carousel-slide">
            <img className="smooth-carousel-image" src={slide.image} alt={slide.alt} />
            <div className="smooth-carousel-overlay"></div>
            <div className="smooth-carousel-content">
              <img className="smooth-carousel-quote-icon" src={quote_icon} alt="Quote icon" />
              <div className="smooth-carousel-text">{slide.text}</div>
              <img className="smooth-carousel-quote-icon flip" src={quote_icon} alt="Quote icon" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

function HomePage() {
    // Javascript animations for page sections and elements --------------------------------------------->
    // Jump to sections smoothly animation
    const scrollToSection = (id) => {
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    };
    
    // Animation for "Pitch" Section
    useEffect(() => {
        const pitchWrapper = document.querySelector('.home-pitch-wrapper');
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('slide-in');
                    observer.unobserve(entry.target);
                }
            });
        }, {
            threshold: 0.1
        });
        if (pitchWrapper) {
            observer.observe(pitchWrapper);
        }
        return () => {
            if (pitchWrapper) {
                observer.unobserve(pitchWrapper);
            }
        };
    }, []);
    // Animations for "Pitch" Statistics
    useEffect(() => {
        const icons = document.querySelectorAll('.home-pitch-statistic');
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry, index) => {
                if (entry.isIntersecting) {
                    entry.target.style.animationDelay = `${index * 0.3}s`;
                    entry.target.classList.add('fade-in');
                    observer.unobserve(entry.target);
                }
            });
        }, {
            threshold: 0.1
        });
        icons.forEach(icon => {
            observer.observe(icon);
        });
        return () => {
            icons.forEach(icon => {
                observer.unobserve(icon);
            });
        };
    }, []);
    // Animation "How" Section
    useEffect(() => {
        const howWrapper = document.querySelector('.home-how-wrapper');
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('slide-in');
                    observer.unobserve(entry.target);
                }
            });
        }, {
            threshold: 0.1
        });
        if (howWrapper) {
            observer.observe(howWrapper);
        }
        return () => {
            if (howWrapper) {
                observer.unobserve(howWrapper);
            }
        };
    }, []);
    // Animation for "How it works" section
    useEffect(() => {
        const elements = document.querySelectorAll('.home-how-timeline-content');
        const observer = new IntersectionObserver((entries, observer) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              entry.target.classList.add('fade-in');
              observer.unobserve(entry.target);
            }
          });
        }, {
          threshold: 0.1
        });
        elements.forEach(element => {
          observer.observe(element);
        });
        return () => {
          elements.forEach(element => {
            observer.unobserve(element);
          });
        };
    }, []);
    // Animation for "Why" Section
    useEffect(() => {
        const whyWrapper = document.querySelector('.home-why-wrapper');
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('slide-in');
                    observer.unobserve(entry.target);
                }
            });
        }, {
            threshold: 0.1
        });
        if (whyWrapper) {
            observer.observe(whyWrapper);
        }
        return () => {
            if (whyWrapper) {
                observer.unobserve(whyWrapper);
            }
        };
    }, []);
    // Animations for "Why" Entries (Icons and text)
    useEffect(() => {
        const icons = document.querySelectorAll('.home-why-col');
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry, index) => {
                if (entry.isIntersecting) {
                    entry.target.style.animationDelay = `${index * 0.3}s`;
                    entry.target.classList.add('fade-in');
                    observer.unobserve(entry.target);
                }
            });
        }, {
            threshold: 0.1
        });
        icons.forEach(icon => {
            observer.observe(icon);
        });
        return () => {
            icons.forEach(icon => {
                observer.unobserve(icon);
            });
        };
    }, []);
    // Animation for "Call to action" section
    useEffect(() => {
        const ctaWrapper = document.querySelector('.home-cta-wrapper');
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('slide-in');
                    observer.unobserve(entry.target); // Stop observing once the animation is applied
                }
            });
        }, {
            threshold: 0.1 // Adjust this value as needed
        });
        if (ctaWrapper) {
            observer.observe(ctaWrapper);
        }
        return () => {
            if (ctaWrapper) {
                observer.unobserve(ctaWrapper);
            }
        };
    }, []);
    // Actual page content --------------------------------------------------------------------------------------------------------------------->
    return(
        <div className='home-wrapper'>
            {/* Hero Section */}
            <div className='home-hero-wrapper-container'>
            <div className='home-hero-wrapper' sm={1} lg={2}>
                <div className='home-hero-wrapper-overlay'></div>
                <div className='home-hero-wrapper-color-overlay'></div>
                <div className='home-hero-row'>
                    <div className='home-hero-col-text'>
                        <div className='home-hero-container'>
                            <div className='home-hero-huge'><b>Real Athletes, Affordable Advice.</b></div>
                            <div className='home-hero-text'>
                                <div className='home-hero-text-line'>Ask fitness questions to experienced athletes and coaches.</div>
                                <div className='home-hero-text-line'>No generic tips. No expensive workout plans.</div>
                                <div className='home-hero-text-line'>Just quick advice tailored to your goals and needs.</div>
                            </div>
                            <div className='home-hero-button-container'>
                                <Button className='home-hero-button-join' href="/ask"><b>Get Advice Now</b></Button>
                            </div>
                            <div className='home-hero-athlete-text'>
                                Athletes, <a href="/benefits" className='home-hero-athlete-link'><b>tap here</b></a> to see how Infield helps you.
                            </div>
                        </div>
                    </div>
                    <div className='home-hero-col-image'>
                        {/* <img className="home-hero-picture" src={phone_hero2} alt="Infield hero section photo"/> */}
                        <img className="home-hero-iphone" src={iphone_messages} alt="Infield hero section photo"/>
                    </div>
                </div>
            </div>
        </div>

        {/* Pitch Section */}
        <div className='home-pitch-background'>
            <div className='home-pitch-wrapper'>
                <div className='home-pitch-row'>
                    <div className='home-pitch-col-text'>
                        <div className='home-pitch-title'>
                            <b>Never settle for random social media advice again</b>
                        </div>
                        <div className='home-pitch-subtitles'>
                            <p>Infield lets you ask short questions to experienced coaches and athletes. <b>No signup required.</b></p>
                            <p>Pick an athlete, share your fitness style and goals, and ask anything.</p>
                            <p><b>It's as easy as sending a DM</b>—except on Infield, your questions actually get answered.</p>
                        </div>
                    </div>
                    <div className='home-pitch-col-visual'>
                        <TopicsScroll />
                    </div>
                </div>
            </div>
        </div>

            {/* How */}
            <div className='home-how-wrapper'>
                <div className='home-how-header'>
                    <b>How Infield works</b>
                </div>
                <div className='home-how-subtitle'>
                    You can write a question, or submit a video for technical feedback.
                </div>
                <div className='home-how-row'>
                    <div className='home-how-col-slideshow'>
                        <div className='home-how-slideshow'>
                            <SmoothCarousel />
                        </div>
                    </div>
                    <div className="home-how-timeline">
                        <div className="home-how-timeline-entry">
                            <div className="home-how-timeline-circle">
                                <div>1</div>
                            </div>
                            <div className="home-how-timeline-content">
                                <div className='home-how-timeline-title'><FaPaperPlane className='home-how-timeline-icon'/>Ask a Question</div>
                                <div className='home-how-timeline-text'>
                                    Submit a short question to the athlete of your choice. <b>No signup required.</b>
                                </div>
                            </div>
                        </div>
                        <div className="home-how-timeline-entry">
                            <div className="home-how-timeline-circle">
                                <div>2</div>
                            </div>
                            <div className="home-how-timeline-content">
                                <div className='home-how-timeline-title'><MdVerified className='home-how-timeline-icon'/>Quick Verification</div>
                                <div className='home-how-timeline-text'>
                                    We quickly screen questions before routing them to the athlete. 
                                </div>
                            </div>
                        </div>
                        <div className="home-how-timeline-entry">
                            <div className="home-how-timeline-circle">
                                <div>3</div>
                            </div>
                            <div className="home-how-timeline-content">
                                <div className='home-how-timeline-title'><BsPersonVideo className='home-how-timeline-icon'/>Athletes Respond</div>
                                <div className='home-how-timeline-text'>
                                    If athletes accept your question, they will send a recorded answer within 72 hours. If they don't, <b>you don't get charged.</b>
                                </div>
                            </div>
                        </div>
                        <div className="home-how-timeline-entry">
                            <div className="home-how-timeline-circle">
                                <div>4</div>
                            </div>
                            <div className="home-how-timeline-content">
                                <div className='home-how-timeline-title'><IoMdChatbubbles className='home-how-timeline-icon'/>Get an Answer</div>
                                <div className='home-how-timeline-text'>
                                    We email you a private link to view the answer. If you have an account, view it on your Infield dashboard.
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Why */}
            <div className="home-why-wrapper">
                <div className='home-why-header'>
                    <b>The Infield difference</b>
                </div>
                <div className='home-why-subtitle'>
                    Get direct access to experienced athletes who understand your journey and challenges.
                </div>
                <div className='home-why-row' style={{ maxWidth: 'var(--widthContent_MAX)', margin: '0 auto' }}>
                    <div className='home-why-col'>
                        <img className='home-why-icon' src={exclusive_icon}></img>
                        <div className='home-why-title'><b>Personalized Answers</b></div>
                        <hr className='home-why-line'></hr>
                        <div className='home-why-text'>
                            Share context around your question so the answer is right for <b>you</b>.
                        </div>
                    </div>
                    <div className='home-why-col'>
                        <img className='home-why-icon' src={perks_DM}></img>
                        <div className='home-why-title'><b>Guaranteed Visibility</b></div>
                        <hr className='home-why-line'></hr>
                        <div className='home-why-text'>
                            Athletes see all questions we approve.
                        </div>
                    </div>
                    <div className='home-why-col'>
                        <img className='home-why-icon' src={perks_fast}></img>
                        <div className='home-why-title'><b>Quick Interactions</b></div>
                        <hr className='home-why-line'></hr>
                        <div className='home-why-text'>
                            Ask in minutes, get answers within 72 hours.
                        </div>
                    </div>
                    <div className='home-why-col'>
                        <img className='home-why-icon' src={perks_real}></img>
                        <div className='home-why-title'><b>Real-world Expertise</b></div>
                        <hr className='home-why-line'></hr>
                        <div className='home-why-text'>
                            We only work with experienced and helpful athletes.
                        </div>
                    </div>
                    <div className='home-why-col'>
                        <img className='home-why-icon' src={perks_nocharge}></img>
                        <div className='home-why-title'><b>No Answer, No Charge</b></div>
                        <hr className='home-why-line'></hr>
                        <div className='home-why-text'>
                            You don't pay until you get a response.
                        </div>
                    </div>
                    <div className='home-why-col'>
                        <img className='home-why-icon' src={perks_history}></img>
                        <div className='home-why-title'><b>Chat History</b></div>
                        <hr className='home-why-line'></hr>
                        <div className='home-why-text'>
                            Athlete never forgets past questions and context.
                        </div>
                    </div>
                </div>
            </div>

            {/* Call */}
            <div className='home-cta-wrapper'>
                <HomeCTASection />
            </div>
        </div>
    );
}

export default HomePage;
