import React, { useEffect, useState } from 'react';
import '../styles/forathletes.css';
import { 
  FaMobileAlt, 
  FaComments, 
  FaVideo,
  FaShieldAlt,
  FaPaperPlane 
} from 'react-icons/fa';
import { GiMoneyStack } from "react-icons/gi";
import { IoIosLink } from "react-icons/io";
import { MdOutlineVerifiedUser } from "react-icons/md";
import { MdOutlineLocalPolice } from "react-icons/md";
import { Button } from 'react-bootstrap';

import iphone_messages from '../iphone_nobg.png';

const ForAthletesPage = () => {
  const [activeContent, setActiveContent] = useState(3);
  const [nextContent, setNextContent] = useState(4);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      // Start fade out
      setIsAnimating(true);

      // After fade out completes, update content
      setTimeout(() => {
        setActiveContent(prev => {
          const next = prev === 3 ? 4 : prev === 4 ? 5 : 3;
          setNextContent(next === 3 ? 4 : next === 4 ? 5 : 3);
          return next;
        });
        setIsAnimating(false);
      }, 750);
      
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const getColumnContent = (columnIndex) => {
    if (columnIndex <= 2) return '';
    if (window.innerWidth > 900) return columnIndex === activeContent ? 'active' : '';
    return isAnimating ? 'fade-out' : 'active';
  };

  const getColumnTitle = (columnIndex) => {
    if (window.innerWidth > 900 || columnIndex <= 2) return null;
    
    // For mobile, show current content
    switch (activeContent) {
      case 3: return "Social Media\nSubscriptions";
      case 4: return "1:1 Consulting\nPlatforms";
      case 5: return "Fan Community\nPlatforms";
      default: return "";
    }
  };

  const getColumnValue = (columnIndex, values) => {
    if (window.innerWidth > 900 || columnIndex <= 2) return values[0];
    
    // For mobile, show the value corresponding to active content
    switch (activeContent) {
      case 3: return values[0];
      case 4: return values[1];
      case 5: return values[2];
      default: return values[0];
    }
  };

  return (
    <div className='benefits-wrapper'>
      {/* Hero Section */}
      <div className='benefits-hero-background'>
        <section className="benefits-section-hero">
          <div className='home-hero-wrapper-overlay'></div>
          <div className='home-hero-wrapper-color-overlay'></div>
          <div className='benefits-hero-row'>
            <div className='benefits-hero-container'>
              <div className='benefits-hero-huge'>Turn your expertise into income.<br/>On your terms.</div>
              <div className='benefits-hero-text'>
                <div className='home-hero-text-line'>Get paid to answer quick fitness questions, whenever it fits your schedule.</div>
                <div className='home-hero-text-line'>No commitments, no hassles. Just earnings.</div>
              </div>
              <div className='benefits-hero-button-container'>
                <Button className='benefits-hero-button-join' href="/athletejoin"><b>Create a free account</b></Button>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* How It Works Section */}
      <div className='benefits-how-background'>
        <section className="benefits-section-how">
          <h2 className="benefits-section-title">How we help you earn</h2>
          <div className="benefits-section-subtitle">Create a free account, set your question price, and start earning.</div>
          <div className="benefits-how-content">
            <div className="benefits-how-image-col">
              <img className="benefits-hero-iphone" src={iphone_messages} alt="Infield app interface"/>
            </div>
            <div className="benefits-how-timeline-col">
              <div className="benefits-timeline">
                <div className="benefits-timeline-entry">
                  <div className="benefits-timeline-circle">
                    <div>1</div>
                  </div>
                  <div className="benefits-timeline-content">
                    <div className='benefits-timeline-title'><IoIosLink className='benefits-timeline-icon'/>Get Questions</div>
                    <div className='benefits-timeline-text'>
                      People send you short questions using your Infield link.
                    </div>
                  </div>
                </div>
                <div className="benefits-timeline-entry">
                  <div className="benefits-timeline-circle">
                    <div>2</div>
                  </div>
                  <div className="benefits-timeline-content">
                    <div className='benefits-timeline-title'><MdOutlineVerifiedUser className='benefits-timeline-icon'/>We Verify Questions</div>
                    <div className='benefits-timeline-text'>
                    We screen each question before it reaches your dashboard.
                    </div>
                  </div>
                </div>
                <div className="benefits-timeline-entry">
                  <div className="benefits-timeline-circle">
                    <div>3</div>
                  </div>
                  <div className="benefits-timeline-content">
                    <div className='benefits-timeline-title'><FaVideo className='benefits-timeline-icon'/>Record Answers</div>
                    <div className='benefits-timeline-text'>
                      Choose which questions to answer via video, and respond within 72 hours.
                    </div>
                  </div>
                </div>
                <div className="benefits-timeline-entry">
                  <div className="benefits-timeline-circle">
                    <div>4</div>
                  </div>
                  <div className="benefits-timeline-content">
                    <div className='benefits-timeline-title'><GiMoneyStack className='benefits-timeline-icon'/>Get Paid</div>
                    <div className='benefits-timeline-text'>
                      Earn money immediately after submitting your response.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Why Infield Section */}
      <div className='benefits-pitch-background'>
        <section className="benefits-section-pitch">
          <h2 className="benefits-section-title">The only platform designed for busy athletes</h2>
          <div className="benefits-section-subtitle">
            Other platforms make you spend time creating content and building a new fanbase.<br/>
            <div style={{marginTop: "0.75rem"}}>
              <b style={{color: "var(--colorPrimary)"}}>Infield doesn't.</b>
            </div>
          </div>
          <table className="benefits-comparison-table">
            <thead>
              <tr>
                <th></th>
                <th><span>Infield</span></th>
                <th className={getColumnContent(3)}>
                  <span>{window.innerWidth <= 900 ? getColumnTitle(3) : "Social Media Subscriptions"}</span>
                </th>
                {window.innerWidth > 900 && (
                  <>
                    <th className={getColumnContent(4)}><span>1:1 Consulting Platforms</span></th>
                    <th className={getColumnContent(5)}><span>Fan Community Platforms</span></th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>No content to build</td>
                <td><span>✅</span></td>
                <td className={getColumnContent(3)}>
                  <span>{window.innerWidth <= 900 ? getColumnValue(3, ["", "✅", ""]) : ""}</span>
                </td>
                {window.innerWidth > 900 && (
                  <>
                    <td className={getColumnContent(4)}><span>✅</span></td>
                    <td className={getColumnContent(5)}><span></span></td>
                  </>
                )}
              </tr>
              <tr>
                <td>Utilize your existing social media audience</td>
                <td><span>✅</span></td>
                <td className={getColumnContent(3)}>
                  <span>{window.innerWidth <= 900 ? getColumnValue(3, ["✅", "✅", ""]) : "✅"}</span>
                </td>
                {window.innerWidth > 900 && (
                  <>
                    <td className={getColumnContent(4)}><span>✅</span></td>
                    <td className={getColumnContent(5)}><span></span></td>
                  </>
                )}
              </tr>
              <tr>
                <td>Questions are pre-screened</td>
                <td><span>✅</span></td>
                <td className={getColumnContent(3)}>
                  <span>{window.innerWidth <= 900 ? getColumnValue(3, ["", "", ""]) : ""}</span>
                </td>
                {window.innerWidth > 900 && (
                  <>
                    <td className={getColumnContent(4)}><span></span></td>
                    <td className={getColumnContent(5)}><span></span></td>
                  </>
                )}
              </tr>
              <tr>
                <td>Full control over what you answer</td>
                <td><span>✅</span></td>
                <td className={getColumnContent(3)}>
                  <span>{window.innerWidth <= 900 ? getColumnValue(3, ["", "✅", ""]) : ""}</span>
                </td>
                {window.innerWidth > 900 && (
                  <>
                    <td className={getColumnContent(4)}><span>✅</span></td>
                    <td className={getColumnContent(5)}><span></span></td>
                  </>
                )}
              </tr>
              <tr>
                <td>Supports follow-up requests for recurring revenue</td>
                <td><span>✅</span></td>
                <td className={getColumnContent(3)}>
                  <span>{window.innerWidth <= 900 ? getColumnValue(3, ["", "", "✅"]) : ""}</span>
                </td>
                {window.innerWidth > 900 && (
                  <>
                    <td className={getColumnContent(4)}><span></span></td>
                    <td className={getColumnContent(5)}><span>✅</span></td>
                  </>
                )}
              </tr>
            </tbody>
          </table>
        </section>
      </div>

      {/* Features Section */}
      <div className='benefits-features-background'>
        <section className="benefits-section-features">
          <h2 className="benefits-section-title">Make your expertise work for you</h2>
          <div className="benefits-section-subtitle">
            Everything you need to earn on your own terms.<br/>
          </div>
          <div className="benefits-features">
            <div className="benefits-feature">
              <FaMobileAlt className="benefits-feature-icon" />
              <h3>Fast Q&A's</h3>
              <p>Questions usually take less than 3 minutes to answer.</p>
            </div>
            <div className="benefits-feature">
              <FaComments className="benefits-feature-icon" />
              <h3>Organized Chats</h3>
              <p>Reference previous conversations, no need to take notes.</p>
            </div>
            <div className="benefits-feature">
              <FaVideo className="benefits-feature-icon" />
              <h3>Built-in Recording</h3>
              <p>Record and upload responses directly from your phone or laptop.</p>
            </div>
            <div className="benefits-feature">
              <MdOutlineLocalPolice className="benefits-feature-icon" />
              <h3>Question Screening</h3>
              <p>We pre-screen questions so you never see anything inappropriate.</p>
            </div>
            <div className="benefits-feature">
              <FaShieldAlt className="benefits-feature-icon" />
              <h3>Private Responses</h3>
              <p>Fans can't download videos, but you can use them for content.</p>
            </div>
            <div className="benefits-feature">
              <FaPaperPlane className="benefits-feature-icon" />
              <h3>Direct DM Routing</h3>
              <p>Opt-into Instagram DM routing so requests go to your Infield page.</p>
            </div>
          </div>
        </section>
      </div>

      {/* CTA Section */}
      <div className='benefits-cta-background'>
        <section className="benefits-section-cta">
          <h2 className="benefits-section-title">Ready to Start Earning?</h2>
          <div className="benefits-section-subtitle">Join a growing network of athletes and their followers on Infield.</div>
          <Button href="/athletejoin" className="benefits-cta-button">Create Your Account</Button>
        </section>
      </div>
    </div>
  );
};

export default ForAthletesPage;