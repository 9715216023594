import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { supabase } from '../SupabaseClient'; // Import Supabase client
import logo_icon from '../developedcolourtest.png';

function TopNavbarAthlete() {
  // Sign-out function
  const handleSignOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Error signing out:', error.message);
    } else {
      window.location.href = '/';
    }
  };
  return (
    <div className='nav-wrapper'>
      <Navbar collapseOnSelect expand="sm" data-bs-theme="dark" className='nav-bar'>
        <Nav className='navLogo'>
          <Nav.Link href="/">
              <img src={logo_icon} alt="Logo" className='nav-logo' />
            </Nav.Link>
        </Nav>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className='nav-hamburger'/>
        <Navbar.Collapse id="responsive-navbar-nav" className='navElements navbar-light'>
          <Nav className="navVertAlign">
            <Nav.Link href="/dashboard" className='navElementLinks'>
              <div className='navElement'>
                Your Dashboard
              </div>
            </Nav.Link>
            <Nav.Link href="/ask" className='navElementLinks'>
              <div className='navElement'>
                Infielder List
              </div>
            </Nav.Link>
            <Nav.Link onClick={handleSignOut} className='navElementLinks'>
                <Button className='navButton'>Sign out</Button>
              </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>

  );
}

export default TopNavbarAthlete;