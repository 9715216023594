import { useParams, Link } from "react-router-dom";
import { Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { supabase } from '../SupabaseClient';
import { MdOutlineStars } from "react-icons/md";
import { RxLightningBolt } from "react-icons/rx";
import { AiOutlineWechat } from "react-icons/ai";
import { IoIosStar } from "react-icons/io"; // Import the star icon
import { BsMoonStars } from "react-icons/bs";
import { MdQuestionAnswer } from "react-icons/md";
import instagram_logo from '../instagram_logo.svg';
import tiktok_logo from '../tiktok_logo.svg';
import x_logo from '../x_logo.svg';
import ReactCountryFlag from "react-country-flag"; // Add this import at the top of the file
import countryCodes from '../data/countries.json';
import RequestFormComponent from '../components/RequestFormComponent';
import { IoMdClose } from 'react-icons/io';
import { IoClose } from "react-icons/io5"; // Add this import

// Add the PromoPopup component
const PromoPopup = ({ onClose }) => {
    return (
        <div className="promo-popup-overlay">
            <div className="promo-popup">
                <button className="promo-popup-close" onClick={onClose}>
                    <IoClose />
                </button>
                <div className="promo-popup-content">
                    <h2>Free First Question</h2>
                    <div className="promo-divider"></div>
                    <p className="promo-text">Ask a personalized fitness or sports question, and get a 40-second video answer within 3 days.</p>
                    <p className="promo-text"><b>No signup required.</b></p>
                    <button className="promo-popup-button" onClick={onClose}>
                        Continue
                    </button>
                </div>
            </div>
        </div>
    );
};

// Add the SubmissionConfirmation component
const SubmissionConfirmation = ({ onClose }) => {
    return (
        <div className="request-form-overlay">
            <div className="request-form-container">
                <button className="close-button" onClick={onClose}>
                    <IoMdClose />
                </button>

                <div className="confirmation-content">
                    <h2>Question Submitted!</h2>
                    <p>
                        If you don't see a confirmation email in your inbox, please check your spam folder. 
                        If your request is approved, the athlete will respond within 3 days, or you will receive a full refund.
                    </p>
                </div>
            </div>
        </div>
    );
};

// Add the InfoWidget component
const InfoWidget = () => {
    return (
        <div className="info-widget">
            <div className="info-widget-content">
                <div className="info-widget-title">What is Infield?</div>
                <div className="info-widget-description">
                    Infield lets you get personalized fitness advice from real athletes and experts. When you ask a question, you will get a 40-second video response from the athlete within 3 days, or you won't be charged.
                </div>
                <div className="info-widget-description" style={{marginTop:"10px"}}>
                   Tap "Ask a Question" to try it out!
                </div>
            </div>
        </div>
    );
};

function AthleteProfilePage() {
    const { username } = useParams(); // Get the username from the URL
    const [athlete, setAthlete] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [ratingScore, setRatingScore] = useState(0); // State for rating score
    const [showRequestForm, setShowRequestForm] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showPromoPopup, setShowPromoPopup] = useState(false); // Initialize as false by default

    // Function to handle popup close
    const handlePromoPopupClose = () => {
        setShowPromoPopup(false);
        localStorage.setItem('hasSeenPromoPopup', 'true');
    };

    // Updated function to use the JSON file
    const getCountryCode = (countryName) => {
        return countryCodes[countryName] || countryName;
    };

    useEffect(() => {
        const fetchAthleteData = async () => {
            try {
                const { data, error } = await supabase
                    .from('users')
                    .select('*, city, country, promo') // Add promo to the selection
                    .eq('username', username)
                    .single();

                if (error) throw error;
                setAthlete(data);
                // Only show popup if athlete has promo enabled and user hasn't seen it
                if (data.promo && !localStorage.getItem('hasSeenPromoPopup')) {
                    setShowPromoPopup(true);
                }
                if (data.rating_count > 0) {
                    setRatingScore(data.rating_total / data.rating_count);
                }
            } catch (error) {
                setError("Sorry, we can't seem to find that athlete in our network.");
            } finally {
                setLoading(false);
            }
        };
        fetchAthleteData();
    }, [username]);

    useEffect(() => {
        if (athlete && athlete.cover_pics.length > 0) {
            const interval = setInterval(() => {
                setCurrentImageIndex((prevIndex) => (prevIndex + 1) % athlete.cover_pics.length);
            }, 5000); // Change image every 5 seconds
            return () => clearInterval(interval);
        }
    }, [athlete]);

    const handleRequestSubmit = () => {
        setShowRequestForm(false);
        setShowConfirmation(true);
    };

    if (loading) {
        return (
            <div className="dashboard-page-format">
                <div className="loader-ring"><div></div><div></div><div></div><div></div></div>
            </div>
        );
    }

    if (error || !athlete) {
        return <div>{error}</div>;
    }

    if (!athlete) {
        return <div>Sorry, we can't seem to find that athlete in our network</div>;
    }
    const { firstName, lastName, profile_pic, bio, sport, position, achievements, interests, instagram, tiktok, x, cover_pics, paused, city, country } = athlete;

    const renderStars = (score) => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            const fillPercentage = Math.min(Math.max(score - (i - 1), 0), 1) * 100; // Fill percentage for each star
            stars.push(
                <div className="profile-star" key={i}>
                    {/* Gray star (background) */}
                    <IoIosStar className="profile-star-gray"/>
                    {/* Gold star (overlay) */}
                    <IoIosStar className="profile-star-color"
                        style={{
                            clipPath: `inset(0 ${100 - fillPercentage}% 0 0)`
                        }}
                    />
                </div>
            );
        }
        return stars;
    };

    return (
        <div className='profile-wrapper'>
            {showPromoPopup && (
                <PromoPopup onClose={handlePromoPopupClose} />
            )}
            <div className="profile-cover-section">
                <div className='profile-cover'>
                    {cover_pics && cover_pics.length > 0 && cover_pics.map((pic, index) => (
                        <img
                            key={index}
                            className={`profile-cover-image ${index === currentImageIndex ? 'active' : ''}`}
                            src={pic}
                            alt="Cover"
                        />
                    ))}
                    <img className="profile-picture" src={profile_pic} alt={`${firstName} ${lastName}`} />
                </div>
                <div className="profile-name-cover">
                    <div className="profile-name-container">
                        <div className="profile-name"><b>{firstName} {lastName}</b></div>
                        {position && position.trim() !== "" ? (
                            <div className="profile-sport">{position}, {sport}</div>
                        ) : (
                            <div className="profile-sport">{sport}</div>
                        )}
                        <div className="profile-location">
                            {city && country && (
                                <>
                                    {city}, {country}{' '}
                                    <ReactCountryFlag 
                                        countryCode={getCountryCode(country)} 
                                        svg 
                                        style={{
                                            marginLeft: '8px',  // Add left margin to create space
                                            width: '1em',       // Set width to match text size
                                            height: '1em'       // Set height to match text size
                                        }}
                                    />
                                </>
                            )}
                        </div>
                        <div className="profile-achievement-container">
                            {achievements.map((achievement, index) => (
                                <div key={index} className='profile-achievement'>
                                    <div style={{display:"flex",flexDirection:"row"}}>
                                        <MdOutlineStars className="profile-achievement-icon" />
                                    </div>
                                    <div>
                                        {achievement}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="profile-socialmedia-container">
                            {instagram && instagram.trim() !== "" && 
                                <a className='profile-socialmedia' href={`https://instagram.com/${instagram}`} target="_blank">
                                    <img className="profile-socialmedia-icon" src={instagram_logo}></img> @{instagram}
                                </a>
                            }
                            {tiktok && tiktok.trim() !== "" && 
                                <a className='profile-socialmedia' href={`https://tiktok.com/@${tiktok}`} target="_blank">
                                    <img className="profile-socialmedia-icon" src={tiktok_logo}></img> @{tiktok}
                                </a>
                            }
                            {x && x.trim() !== "" && 
                                <a className='profile-socialmedia' href={`https://x.com/${x}`} target="_blank">
                                    <img className="profile-socialmedia-icon" src={x_logo}></img> @{x}
                                </a>
                            }
                        </div>
                        <div className="profile-interact">
                            {paused ? ( 
                                <Button className='profile-button-paused' disabled>
                                    <BsMoonStars className='profile-button-icon' />
                                    <span className="profile-button-text">Requests are Paused</span>
                                </Button>
                            ) : (
                                <Button 
                                    className='profile-button-active' 
                                    onClick={() => setShowRequestForm(true)}
                                >
                                    <AiOutlineWechat className='profile-button-icon' />
                                    <span className="profile-button-text">Ask a Question</span>
                                </Button>
                            )}
                            <div className="profile-rating">
                                <div className="profile-rating-top">
                                    {renderStars(ratingScore)}
                                    <span className="rating-score">{ratingScore.toFixed(1)}</span>
                                </div>
                                <div className="rating-text">Response Rating</div>
                            </div>
                        </div>
                        {paused &&
                            <div className="profile-bio" style={{margin:"5px 0", color:"var(--colorPrimary)"}}>{firstName} has temporarily paused new requests. Check back again soon!</div>
                        }
                    </div>
                </div>
            </div>
            <div className="profile-section">
                <div className="profile-header">About {firstName}</div>
                <div className="profile-bio">{bio}</div>
            </div>
            <div className="profile-section">
                <div className="profile-header">Areas of insight</div>
                {interests.map((interest, index) => (
                    <div key={index} className='profile-expertise'><RxLightningBolt className="profile-expertise-icon" />{interest}</div>
                ))}
            </div>
            {showRequestForm && (
                <RequestFormComponent 
                    athlete={athlete} 
                    onClose={() => setShowRequestForm(false)}
                    onSubmitSuccess={handleRequestSubmit}
                />
            )}
            {showConfirmation && (
                <SubmissionConfirmation 
                    onClose={() => setShowConfirmation(false)}
                />
            )}
            <InfoWidget />
        </div>
    );
}

export default AthleteProfilePage;
