import React, { useState } from 'react';
import { LuHelpCircle } from "react-icons/lu";

// assets
import dm_integration from '../dm_integration.png';

function HelpTab({ athleteProfile }) {
    const [expandedFAQ, setExpandedFAQ] = useState(null);

    const handleFAQClick = (index) => {
        setExpandedFAQ(expandedFAQ === index ? null : index);
    };

    const faqs = [
        // {
        //     question: (<div style={{color: "#04AA6D"}}>GUIDE: Getting started with Infield</div>),
        //     answer: (
        //         <div>
        //             <p>Follow the steps below to active your Infield account.</p>
        //             <p><b>Step 1: Set your price.</b> Go to the "Profile" tab, and choose the price you want to charge for each question sent to you. For your first week of Infield, you have the option to not require payment for requests. Some athletes have asked to start as payment-optional to get more initial traffic to their profile and to build trust in their expertise with their audience. If you choose payment-optional, requesters will still be asked if they wish to send payment.</p>
        //             <p><b>Step 2: Choose your start date.</b> Use the blue chat bubble on the bottom of page to tell our team when you want to your account to begin accepting requests.</p>
        //             <p><b>Step 3: Annouce to your followers.</b> To maximize reach, we suggest that you put your Infield page link in your bios or linktrees for your social media pages once you create a profile. We also suggest you make 1-2 stories and/or posts announcing to your followers that you are answering questions through Infield once you begin using it. We can design a story or post for you, just ask!</p>
        //             <p><b>Step 4: Review the answering process.</b> This 40-second video walks you through how to respond to requests <a href='https://drive.google.com/file/d/1v0bZKIFCaVm1Uf_lQIQ7vCaGhXxdYXJA/view?usp=drive_link' target='_blank' style={{color: "blue", fontWeight: "bold" }}>here</a>.</p>
        //         </div>
        //     )
        // },
        {
            question: (<div style={{color: "#04AA6D"}}>GUIDE: Setting up payments to receive requests</div>),
            answer: (
                <div>
                    <p>Infield uses Stripe to securely handle purchases and payments so that we don't collect any payment information from you or your fans. Follow the steps below to set up your payment information. If you run into any issues, use the chat button in the bottom right corner to message our team.</p>
                    <p><b>Step 1:</b> Go to your "Profile" tab and select the "Set up payments" button to begin the signup process on their Stripe's website.</p>
                    <p><b>Step 2:</b> Confirm your phone number with the website. When asked for "Type of business", select "Individual"</p>
                    <p><b>Step 3:</b> For "Industry", select "Consulting services". For "Your website", enter "https://www.infieldconnect.co/" for the URL.</p>
                    <p><b>Step 4:</b> Under "Select an account for payouts", choose your preferred bank or institution.</p>
                    <p><b>Step 5:</b> Under "Add public details for customers", enter "INFIELDCONNECT.CO for the "Statement descriptor".</p>
                    <p><b>Step 6:</b> Under "Review and submit", rwait for your information to finish verifying, review your entered information, and then submit the form.</p>
                </div>
            )
        },
        {
            question: (<div style={{color: "#04AA6D"}}>GUIDE: Receiving requests from your Instagram DMs</div>),
            answer: (
                <div>
                    <p>Follow <a style={{fontWeight: "bold", color: "blue"}} target="_blank" href='https://help.instagram.com/561062241952036'>this quick guide</a> to set up an automated message for new DM requests.</p>
                    <p>Copy this text for the Question:</p>
                    <p className='dashboard-faq-copytext'>Hey! I use Infield to answer questions from my followers. Click to get my link!</p>
                    <p>Copy this text for the Automated Response:</p>
                    <p className='dashboard-faq-copytext'>Infield is a platform that makes it easy for athletes to get face-to-face with their followers for questions, requests, or to just say hi! Click this link to try it for free, no signup required 👉 https://infieldconnect.co/request/{athleteProfile.username}</p>
                    <p>Finally, verify that your question is show and available</p>
                    <img style={{width: "min(100%,400px)"}} src={dm_integration} />
                </div>
            )
        },
        {
            question: "How much time do I have to respond to requests?",
            answer: "Once a request enters your dashboard, you have 3 days to respond. If you don't respond within that time, the request will be automatically rejected, and the sender will be refunded."
        }
    ];

    return (
        <div className="dashboard-tab-content">
            <div className="dashboard-faq-title">Frequently Asked Questions</div>
            <div className="dashboard-faq-section">
                {faqs.map((faq, index) => (
                    <div key={index} className="dashboard-faq-item">
                        <div className="dashboard-faq-question" onClick={() => handleFAQClick(index)}>
                            {faq.question}
                        </div>
                        <div className={`dashboard-faq-answer ${expandedFAQ === index ? 'expanded' : ''}`}>
                            {faq.answer}
                        </div>
                    </div>
                ))}
            </div>
            <div style={{marginTop: "20px"}}><b>Need help with something?</b> Open the feedback messanger in bottom right corner of your screen, and our team will respond as soon as possible.</div>
        </div>
    );
}

export default HelpTab;