import React, { useState, useMemo, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { supabase } from '../SupabaseClient';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import sportsList from '../data/sports.json';

function SignUpPage() {
    const [showForm, setShowForm] = useState(false);
    const [showWelcomePopup, setShowWelcomePopup] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [signupCode, setSignupCode] = useState('');
    const [socialPlatform, setSocialPlatform] = useState('');
    const [socialHandle, setSocialHandle] = useState('');
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [athleteProfile, setAthleteProfile] = useState({
        firstName: '',
        lastName: '',
        username: '',
        city: '',
        country: '',
        sport: '',
        position: '',
        achievements: [''],
        bio: '',
        interests: [''],
        instagram: '',
        tiktok: '',
        x: '',
        no_go_topics: '',
        password: '',
        confirmPassword: '',
        profilePic: null,
        coverPics: [],
        country_code: '',
        dob: ''
    });

    // List of allowed countries
    const allowedCountries = [
        { label: 'Australia', value: 'AU' },
        { label: 'Austria', value: 'AT' },
        { label: 'Belgium', value: 'BE' },
        { label: 'Bulgaria', value: 'BG' },
        { label: 'Canada', value: 'CA' },
        { label: 'Croatia', value: 'HR' },
        { label: 'Cyprus', value: 'CY' },
        { label: 'Czech Republic', value: 'CZ' },
        { label: 'Denmark', value: 'DK' },
        { label: 'Estonia', value: 'EE' },
        { label: 'Finland', value: 'FI' },
        { label: 'France', value: 'FR' },
        { label: 'Germany', value: 'DE' },
        { label: 'Gibraltar', value: 'GI' },
        { label: 'Greece', value: 'GR' },
        { label: 'Hong Kong SAR China', value: 'HK' },
        { label: 'Hungary', value: 'HU' },
        { label: 'Ireland', value: 'IE' },
        { label: 'Italy', value: 'IT' },
        { label: 'Japan', value: 'JP' },
        { label: 'Latvia', value: 'LV' },
        { label: 'Liechtenstein', value: 'LI' },
        { label: 'Lithuania', value: 'LT' },
        { label: 'Luxembourg', value: 'LU' },
        { label: 'Malta', value: 'MT' },
        { label: 'Mexico', value: 'MX' },
        { label: 'Netherlands', value: 'NL' },
        { label: 'New Zealand', value: 'NZ' },
        { label: 'Norway', value: 'NO' },
        { label: 'Poland', value: 'PL' },
        { label: 'Portugal', value: 'PT' },
        { label: 'Romania', value: 'RO' },
        { label: 'Singapore', value: 'SG' },
        { label: 'Slovakia', value: 'SK' },
        { label: 'Slovenia', value: 'SI' },
        { label: 'South Africa', value: 'ZA' },
        { label: 'Spain', value: 'ES' },
        { label: 'Sweden', value: 'SE' },
        { label: 'Switzerland', value: 'CH' },
        { label: 'Thailand', value: 'TH' },
        { label: 'United Arab Emirates', value: 'AE' },
        { label: 'United Kingdom', value: 'GB' },
        { label: 'United States', value: 'US' },
    ];

    // Filter the country list to include only allowed countries
    const countries = useMemo(() => {
        const allCountries = countryList().getData();
        return allCountries.filter(country => 
            allowedCountries.some(allowed => allowed.value === country.value)
        );
    }, []);

    // Add this near the top of the component, after other state declarations
    const sports = useMemo(() => sportsList.map(sport => ({ label: sport, value: sport })), []);

    const handleCodeSubmit = async (e) => {
        e.preventDefault();
        if (signupCode === 'INFIELD5013') {
            setShowForm(true);
            setShowWelcomePopup(true);
        } else {
            alert('Invalid signup code');
        }
    };

    const handleWaitlistSubmit = async (e) => {
        e.preventDefault();
        try {
            const { error } = await supabase
                .from('athlete_form')
                .insert([{
                    social_platform: socialPlatform,
                    social_account: socialHandle,
                    email: email
                }]);

            if (error) {
                console.error('Error!', error.message);
                alert('An error occurred. Please try again.');
            } else {
                setSubmitted(true);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred. Please try again.');
        }
    };

    const handleProfileChange = (e) => {
        const { name, value } = e.target;
        setAthleteProfile((prevProfile) => ({
            ...prevProfile,
            [name]: value
        }));
    };

    const handleInterestChange = (index, value) => {
        setAthleteProfile(prevProfile => {
            const newInterests = [...prevProfile.interests];
            newInterests[index] = value;
            return {
                ...prevProfile,
                interests: newInterests
            };
        });
    };

    const handleAchievementChange = (index, value) => {
        setAthleteProfile(prevProfile => {
            const newAchievements = [...prevProfile.achievements];
            newAchievements[index] = value;
            return {
                ...prevProfile,
                achievements: newAchievements
            };
        });
    };

    const addInterest = () => {
        setAthleteProfile(prevProfile => ({
            ...prevProfile,
            interests: [...prevProfile.interests, ""]
        }));
    };

    const removeInterest = (index) => {
        setAthleteProfile(prevProfile => ({
            ...prevProfile,
            interests: prevProfile.interests.filter((_, i) => i !== index)
        }));
    };

    const addAchievement = () => {
        setAthleteProfile(prevProfile => ({
            ...prevProfile,
            achievements: [...prevProfile.achievements, ""]
        }));
    };

    const removeAchievement = (index) => {
        setAthleteProfile(prevProfile => ({
            ...prevProfile,
            achievements: prevProfile.achievements.filter((_, i) => i !== index)
        }));
    };

    const isFormValid = () => {
        const { username, password, confirmPassword } = athleteProfile;
        const usernameValid = /^[a-z0-9]{1,15}$/.test(username); // Username validation
        const passwordsMatch = password === confirmPassword; // Password confirmation check

        return (
            usernameValid &&
            passwordsMatch
            // Object.values(athleteProfile).every(value => 
            //     (typeof value === 'string' && value.trim() !== '') || 
            //     (Array.isArray(value) && value.length > 0 && value.every(item => item.trim() !== ''))
            // )
        );
    };

    const handleFileChange = (e, type) => {
        const files = Array.from(e.target.files);
        if (type === 'profilePic') {
            setAthleteProfile(prevProfile => ({
                ...prevProfile,
                profilePic: files[0]
            }));
        } else if (type === 'coverPics') {
            setAthleteProfile(prevProfile => ({
                ...prevProfile,
                coverPics: files.slice(0, 3) // Limit to 3 cover photos
            }));
        }
    };

    const uploadFiles = async (username) => {
        const profilePicPath = `${username}/profilePicture/${athleteProfile.profilePic.name}`;
        const { error: profilePicError } = await supabase.storage
            .from('userMedia')
            .upload(profilePicPath, athleteProfile.profilePic);

        if (profilePicError) throw profilePicError;

        const { data: profilePicData } = supabase.storage
            .from('userMedia')
            .getPublicUrl(profilePicPath);
        const profilePicURL = profilePicData.publicUrl; // Ensure correct extraction
        const coverPicURLs = [];
        for (let i = 0; i < athleteProfile.coverPics.length; i++) {
            const coverPicPath = `${username}/coverPictures/${athleteProfile.coverPics[i].name}`;
            const { error: coverPicError } = await supabase.storage
                .from('userMedia')
                .upload(coverPicPath, athleteProfile.coverPics[i]);

            if (coverPicError) throw coverPicError;

            const { data: coverPicData } = supabase.storage
                .from('userMedia')
                .getPublicUrl(coverPicPath);

            coverPicURLs.push(coverPicData.publicUrl); // Ensure correct extraction
        }

        return { profilePicURL, coverPicURLs };
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        
        try {
            // Check if email already exists
            const { data: existingEmail } = await supabase
                .from('users')
                .select('email')
                .eq('email', athleteProfile.email)
                .single();

            if (existingEmail) {
                throw new Error('An account with this email already exists');
            }

            // Check if username already exists
            const { data: existingUsername } = await supabase
                .from('users')
                .select('username')
                .eq('username', athleteProfile.username)
                .single();

            if (existingUsername) {
                throw new Error('This username is already taken');
            }

            // Upload files
            const { profilePicURL, coverPicURLs } = await uploadFiles(athleteProfile.username);

            // Create auth user
            const { data: authData, error: authError } = await supabase.auth.signUp({
                email: athleteProfile.email,
                password: athleteProfile.password,
            });

            if (authError) {
                console.error('Auth Error:', authError);
                throw new Error(`Authentication error: ${authError.message}`);
            }

            // Create athlete profile
            const { error: profileError } = await supabase
                .from('users')
                .insert({
                    uuid: authData.user.id,
                    firstName: athleteProfile.firstName,
                    lastName: athleteProfile.lastName,
                    username: athleteProfile.username,
                    email: athleteProfile.email,
                    city: athleteProfile.city,
                    country: athleteProfile.country,
                    sport: athleteProfile.sport,
                    position: athleteProfile.position,
                    achievements: athleteProfile.achievements,
                    bio: athleteProfile.bio,
                    interests: athleteProfile.interests,
                    instagram: athleteProfile.instagram,
                    tiktok: athleteProfile.tiktok,
                    x: athleteProfile.x,
                    no_go_topics: athleteProfile.no_go_topics,
                    role: 'creator',
                    profile_pic: profilePicURL,
                    cover_pics: coverPicURLs,
                    dob: athleteProfile.dob,
                });

            if (profileError) {
                console.error('Profile Error:', profileError);
                throw new Error(`Profile creation error: ${profileError.message}`);
            }

            setIsSubmitting(false);
            setSubmitted(true);
        } catch (error) {
            console.error('Detailed error:', error);
            setIsSubmitting(false);
            alert(error.message || 'An error occurred during signup');
            return;
        }
    };

    // Add this new component inside SignUpPage
    const WelcomePopup = () => {
        const [isAnimating, setIsAnimating] = useState(false);

        useEffect(() => {
            // Small delay to ensure initial styles are applied
            const timer = setTimeout(() => {
                setIsAnimating(true);
            }, 10);

            return () => clearTimeout(timer);
        }, []);

        return (
            <div className={`welcome-popup-overlay ${isAnimating ? 'animate-in' : ''}`}>
                <div className={`welcome-popup ${isAnimating ? 'animate-in' : ''}`}>
                <h2>Welcome to Infield! 🎉</h2>
                <p>
                    The team is thrilled to have you on board—and so are your fans.
                </p>
                <p>
                    Your insights and experiences are valuable, and now you have a seamless way to connect, engage, and get paid for what you already do best.
                </p>
                <p>
                    Start by creating an Infield profile, and then use your email and password to finish setting 
                    up your payment preferences. If you ever need help, just message us at <b>info@infieldconnect.co</b>.
                </p>
                <p>We can't wait to see you in action! 🚀</p>
                <p>-The Infield Creator Team</p>
                    <button 
                        className="welcome-popup-button"
                        onClick={() => setShowWelcomePopup(false)}
                    >
                        Let's get started
                    </button>
                </div>
            </div>
        );
    };

    return (
        <div className="signup-container">
            {showWelcomePopup && <WelcomePopup />}
            {submitted ? (
                <div className="contact-form">
                    <div className='contact-received-title'>
                        Thank you for your interest!
                    </div>
                    <div className='contact-received-text'>
                        If we believe you may be a good fit for our community, someone from our team will reach out to you soon.
                    </div>
                </div>
            ) : (
                !showForm ? (
                    <div className="signup-code-form">
                        <Form onSubmit={handleCodeSubmit}>
                            <Form.Group>
                                <Form.Label>Enter Signup Code</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={signupCode}
                                    onChange={(e) => setSignupCode(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Button 
                                type="submit" 
                                className='dashboard-contact-button' 
                                style={{ marginTop: '10px', marginBottom: '20px' }}
                            >
                                Submit Code
                            </Button>
                        </Form>

                        <div className='contact-received-text no-top-margin'>
                            Don't have a signup code? Let us know where we can find you:
                        </div>
                        
                        <Form onSubmit={handleWaitlistSubmit}>
                            <Form.Group style={{ marginTop: '10px' }}>
                                <Form.Label>Social Media Platform</Form.Label>
                                <Form.Select
                                    value={socialPlatform}
                                    onChange={(e) => setSocialPlatform(e.target.value)}
                                    required
                                >
                                    <option value="">Select Platform</option>
                                    <option value="X">X</option>
                                    <option value="Instagram">Instagram</option>
                                    <option value="Tiktok">TikTok</option>
                                </Form.Select>
                            </Form.Group>
                            
                            <Form.Group style={{ marginTop: '10px' }}>
                                <Form.Label>Social Media Handle</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={socialHandle}
                                    onChange={(e) => setSocialHandle(e.target.value)}
                                    placeholder="Enter your username"
                                    required
                                />
                            </Form.Group>

                            <Form.Group style={{ marginTop: '10px' }}>
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Enter your email"
                                    required
                                />
                            </Form.Group>
                            
                            <Button 
                                type="submit" 
                                className='dashboard-contact-button'
                                style={{ marginTop: '10px' }}
                                disabled={!socialPlatform || !socialHandle || !email}
                            >
                                Submit Request
                            </Button>
                        </Form>
                    </div>
                ) : (
                    <Form onSubmit={handleSubmit} className="signup-form">
                        <div className="request-header">Create an account</div>
                        <Form.Group>
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="firstName"
                                value={athleteProfile.firstName}
                                onChange={handleProfileChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="lastName"
                                value={athleteProfile.lastName}s
                                onChange={handleProfileChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Date of Birth</Form.Label>
                            <Form.Text className="text-muted">
                                You must be at least 15 years old to create an account.
                            </Form.Text>
                            <Form.Control
                                type="date"
                                name="dob"
                                value={athleteProfile.dob}
                                onChange={handleProfileChange}
                                max={new Date(Date.now() - 15 * 365.25 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]}
                                required
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>City</Form.Label>
                            <Form.Control
                                type="text"
                                name="city"
                                value={athleteProfile.city}
                                onChange={handleProfileChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Country</Form.Label>
                            <Form.Text className="text-muted">
                                <span><b>This should be your country of residence for tax purposes. </b> 
                                If you cannot find your country, then Infield is not yet available in your country.
                                </span>
                            </Form.Text>
                            <Select
                                className="signup-country-dropdown"
                                name="country"
                                options={countries}
                                value={countries.find(option => option.value === athleteProfile.country_code)}
                                onChange={(selectedOption) => {
                                    setAthleteProfile({
                                        ...athleteProfile,
                                        country: selectedOption.label,
                                        country_code: selectedOption.value,
                                    });
                                }}
                                required
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Text className="text-muted">
                                We will send notifications about new requests to this email.
                            </Form.Text>
                            <Form.Control
                                type="email"
                                name="email"
                                value={athleteProfile.email}
                                onChange={handleProfileChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Username</Form.Label>
                            <Form.Text className="text-muted">
                                Must be lowercase letters and numbers only, max 15 characters.
                            </Form.Text>
                            <Form.Control
                                type="text"
                                name="username"
                                value={athleteProfile.username}
                                onChange={handleProfileChange}
                                required
                                maxLength={15} // Limit to 15 characters
                                pattern="[a-z0-9]*" // Only allow lowercase letters and numbers
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Sport or Creator Type</Form.Label>
                            <Form.Text className="text-muted">
                                If your area of expertise is not listed, please select "Other" and mention it in your bio.
                            </Form.Text>
                            <Select
                                className="signup-sport-dropdown"
                                name="sport"
                                options={sports}
                                value={sports.find(option => option.value === athleteProfile.sport)}

                                onChange={(selectedOption) => {
                                    setAthleteProfile({
                                        ...athleteProfile,
                                        sport: selectedOption.value,
                                    });
                                }}
                                required
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Position</Form.Label>
                            <Form.Text className="text-muted">
                                Optional
                            </Form.Text>
                            <Form.Control
                                type="text"
                                name="position"
                                value={athleteProfile.position}
                                onChange={handleProfileChange}
                                placeholder="e.g. Sprinter, Shortstop, etc."
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Achievement highlights (up to 3)</Form.Label>
                            <Form.Text className="text-muted">
                                Add performance highlights, championship titles, and professional affiliations that speak to your expertise. List highlights in order of importance.
                            </Form.Text>
                            {athleteProfile.achievements.map((achievement, index) => (
                                <div key={index} className="achievement-entry">
                                    <Form.Control
                                        type="text"
                                        value={achievement}
                                        onChange={(e) => handleAchievementChange(index, e.target.value)}
                                        placeholder="e.g. NCAA 100m Dash Champion, Nike sponsored athlete"
                                        required
                                    />
                                    {athleteProfile.achievements.length > 1 &&
                                        <Button onClick={() => removeAchievement(index)}>Remove</Button>
                                    }
                                </div>
                            ))}
                            {athleteProfile.achievements.length < 3 &&
                                <Button onClick={addAchievement}>Add Achievement</Button>
                            }
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Bio</Form.Label>
                            <Form.Text className="text-muted">
                                Talk about past achievements, athletic affiliations, and anything else you want to share with potential requesters.
                            </Form.Text>
                            <Form.Control
                                as="textarea"
                                rows="5"
                                name="bio"
                                value={athleteProfile.bio}
                                onChange={handleProfileChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Areas of insight (up to 8)</Form.Label>
                            {athleteProfile.interests.map((interest, index) => (
                                <div key={index} className="interest-entry">
                                    <Form.Control
                                        type="text"
                                        value={interest}
                                        onChange={(e) => handleInterestChange(index, e.target.value)}
                                        placeholder="e.g. Strength training, College Recruiting"
                                        required
                                    />
                                    {athleteProfile.interests.length > 1 &&
                                        <Button onClick={() => removeInterest(index)}>Remove</Button>
                                    }
                                </div>
                            ))}
                            {athleteProfile.interests.length < 8 &&
                                <Button onClick={addInterest}>Add Interest</Button>
                            }
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Instagram</Form.Label>
                            <Form.Text className="text-muted">
                                Don't include the @ symbol.
                            </Form.Text>
                            <Form.Control
                                type="text"
                                name="instagram"
                                value={athleteProfile.instagram}
                                onChange={handleProfileChange}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>TikTok</Form.Label>
                            <Form.Text className="text-muted">
                                Don't include the @ symbol.
                            </Form.Text>
                            <Form.Control
                                type="text"
                                name="tiktok"
                                value={athleteProfile.tiktok}
                                onChange={handleProfileChange}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>X/Twitter</Form.Label>
                            <Form.Text className="text-muted">
                                Don't include the @ symbol.
                            </Form.Text>
                            <Form.Control
                                type="text"
                                name="x"
                                value={athleteProfile.x}
                                onChange={handleProfileChange}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>No-go topics</Form.Label>
                            <Form.Text className="text-muted">
                                Let our team know if there are any types of questions you don't want to answer.
                            </Form.Text>
                            <Form.Control
                                as="textarea"
                                rows="3"
                                name="no_go_topics"
                                value={athleteProfile.no_go_topics}
                                placeholder="e.g. Family, dating, sponsorship contract details"
                                onChange={handleProfileChange}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                name="password"
                                value={athleteProfile.password}
                                onChange={handleProfileChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                                type="password"
                                name="confirmPassword"
                                value={athleteProfile.confirmPassword}
                                onChange={handleProfileChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Profile Picture</Form.Label>
                            <Form.Text className="text-muted">
                                The main picture that will be displayed in your profile.
                            </Form.Text>
                            <Form.Control
                                type="file"
                                accept="image/*"
                                onChange={(e) => handleFileChange(e, 'profilePic')}
                                required
                            />
                            {athleteProfile.profilePic && (
                                <img
                                    src={URL.createObjectURL(athleteProfile.profilePic)}
                                    alt="Profile Thumbnail"
                                    className='signup-picture'
                                    style={{ width: '100px', height: '100px', objectFit: 'cover', marginTop: '10px' }}
                                />
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Cover Photos (up to 3)</Form.Label>
                            <Form.Text className="text-muted">
                                Landscape photos that will be displayed at the top of your profile page.
                            </Form.Text>
                            <Form.Control
                                type="file"
                                accept="image/*"
                                multiple
                                onChange={(e) => handleFileChange(e, 'coverPics')}
                            />
                            {athleteProfile.coverPics.map((file, index) => (
                                <img
                                    key={index}
                                    src={URL.createObjectURL(file)}
                                    alt={`Cover Thumbnail ${index + 1}`}
                                    className='signup-picture'
                                    style={{ width: '100px', height: '100px', objectFit: 'cover', marginRight: '10px', marginTop: '10px' }}
                                />
                            ))}
                        </Form.Group>
                        <button
                            className="request-button"
                            type="submit"
                            disabled={!isFormValid() || isSubmitting}
                        >
                            {isSubmitting ? (
                            <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                                Submitting
                                <div className="loader-button" style={{ marginLeft: '10px' }}>
                                </div>
                            </div>
                            ) : (
                            "Submit Profile"
                            )}
                        </button>
                    </Form>
                )
            )}
        </div>
    );
}

export default SignUpPage;
