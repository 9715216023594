import React from 'react';
import { useNavigate } from 'react-router-dom';

// Icons
import basketball_icon from '../basketball_icon.svg';
import exclusive_icon from '../exclusive_icon.svg';

function JoinPage() {
    const navigate = useNavigate();

    return (
        <div className='join-container'>
            <h2 className='join-header'>Create a free account</h2>
            <div className='join-cards-container'>
                <div 
                    className='join-card'
                    style={{ backgroundColor: 'rgba(var(--colorTertiaryRGB), 0.1)' }}
                    onClick={() => navigate('/athletejoin')}
                >
                    <img src={basketball_icon} alt="Athlete icon" className='join-card-icon' />
                    <h3 className='join-card-title' style={{ color: 'var(--colorTertiary)' }}>Infield Athlete</h3>
                    <p className='join-card-subtitle'>Share your expertise with sports enthusiasts worldwide</p>
                </div>
                <div 
                    className='join-card' 
                    onClick={() => navigate('/fanjoin')}
                >
                    <img src={exclusive_icon} alt="Fan icon" className='join-card-icon' />
                    <h3 className='join-card-title'>Infield Fan</h3>
                    <p className='join-card-subtitle'>Ask questions to real athletes and coaches</p>
                </div>
            </div>
            <div className="join-redirect">
                <p>
                    Don't want to make an account yet? {' '}
                    <a href="/ask" className="join-redirect-link">
                        Try Infield without one
                    </a>
                </p>
            </div>
        </div>
    );
}

export default JoinPage; 