import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { supabase } from '../SupabaseClient';

const AthleteComponent = ({athlete}) => {
    const {firstName, lastName, profile_pic, sport, position, achievements, username, promo} = athlete;

    return (
        <Link className="athlete-entry" to={`/ask/${username}`} key={username}>
            <div className="athlete-entry-image-container">
                <img className="athlete-entry-image" src={profile_pic} alt={`${firstName} ${lastName}`} />
                <div className='athlete-entry-image-overlay'></div>
                {promo && (
                    <div className='athlete-entry-promo'>FREE Question</div>
                )}
                <div className='athlete-entry-info'>
                    <div className='athlete-name'>
                        {firstName} {lastName}
                    </div>

                    {position && position.trim() ? (
                        <div className='athlete-sport'>
                            {position}, {sport}
                        </div>
                    ) : (
                        <div className='athlete-sport'>
                            {sport}
                        </div>
                    )}

                    <div className='athlete-accolade'>
                        {achievements[0]}
                    </div>
                </div>
            </div>
        </Link>
    );
};

function AthleteListPage() {
    const [athletes, setAthletes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [socialHandle, setSocialHandle] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);

    useEffect(() => {
        const fetchAthletes = async () => {
            const { data, error } = await supabase
                .from('users')
                .select('*')
                .eq('role', 'creator');
            if (error) {
                console.error('Error fetching athletes:', error);
            } else {
                setAthletes(data);
            }
        };
        fetchAthletes();
        setLoading(false);
    }, []);
    
    // Appearing animation for athlete cards
    useEffect(() => {
        const athleteEntries = document.querySelectorAll('.athlete-entry');
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry, index) => {
                if (entry.isIntersecting) {
                    entry.target.style.animationDelay = `${index * 0.3}s`; // Staggered delay
                    entry.target.classList.add('fade-in');
                    observer.unobserve(entry.target); // Stop observing once the animation is applied
                }
            });
        }, {
            threshold: 0.1 // Adjust this value as needed
        });
        athleteEntries.forEach(entry => {
            observer.observe(entry);
        });
        return () => {
            athleteEntries.forEach(entry => {
                observer.unobserve(entry);
            });
        };
    }, [athletes]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const { error } = await supabase
            .from('contact_form')
            .insert([{
                content: socialHandle,
                subject: 'Athlete Suggestion',
            }]);

        if (error) {
            console.error('Error submitting suggestion:', error);
        } else {
            setSocialHandle('');
            setShowSuccess(true);
            setTimeout(() => setShowSuccess(false), 3000);
        }
    };

    if (loading) {
        return (
            <div className="dashboard-page-format">
                <div className="loader-ring"><div></div><div></div><div></div><div></div></div>
            </div>
        )
    }
    
    return(
        <div className='home-format'>
            <div className='athlete-wrapper'>
                <div className='athlete-top-row'>
                    <div className='athlete-top-header'>Meet the roster</div>
                    <div className='athlete-top-intro'>
                        Choose an athlete, see how they can help you, and ask them anything.
                    </div>
                </div>
                <div sm={1} lg={2} className='athlete-row'>
                    {athletes.map((athlete) => (
                        <AthleteComponent key={athlete.username} athlete={athlete} />
                    ))}
                </div>
                
                <div className='athlete-suggestion-section'>
                    <h2 className='athlete-suggestion-title'>Have someone else in mind?</h2>
                    <p className='athlete-suggestion-text'>
                        Enter the social media handle of an athlete you'd like to join Infield, and our team will contact them.
                    </p>
                    <form onSubmit={handleSubmit} className='athlete-suggestion-form'>
                        <input
                            type="text"
                            value={socialHandle}
                            onChange={(e) => setSocialHandle(e.target.value)}
                            placeholder="@username"
                            className='athlete-suggestion-input'
                            required
                        />
                        <Button type="submit" className='athlete-suggestion-button'>
                            Submit
                        </Button>
                    </form>
                    {showSuccess && (
                        <div className='athlete-suggestion-success'>
                            Thanks! We'll reach out to them.
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default AthleteListPage;
