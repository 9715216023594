import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const TikTokTracker = () => {
  const location = useLocation();

  useEffect(() => {
    // Track page view when route changes
    if (window.ttq) {
      window.ttq.page();
    }
  }, [location]);

  return null;
};

export default TikTokTracker; 