import React, { useState } from 'react';
import '../styles/onboardpopup.css';
import { supabase } from '../SupabaseClient';
import axios from 'axios';

const OnboardPopup = ({ onClose, username, accessToken, countryCode, email }) => {
    const [currentScreen, setCurrentScreen] = useState(1);
    const [selectedPrice, setSelectedPrice] = useState(500);
    const [showCopied, setShowCopied] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const userLink = `infieldconnect.co/ask/${username}`;

    const handleNextScreen = () => {
        if (currentScreen < 3) {
            setCurrentScreen(currentScreen + 1);
        }
    };

    const handleCopyLink = () => {
        navigator.clipboard.writeText(userLink);
        setShowCopied(true);
        setTimeout(() => setShowCopied(false), 2000);
    };

    const handleStripeConnect = async () => {
        try {
            setIsLoading(true);
            if (!accessToken) {
                throw new Error('User not authenticated.');
            }
            const { data } = await axios.post(
                'https://infield-backend.vercel.app/create-stripe-account-link',
                {
                    country_code: countryCode,
                    email: email
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            );
            window.open(data.url, '_blank');
        } catch (error) {
            console.error('Error connecting to Stripe:', error.message);
            alert('There was an error setting up payments. Please try again or contact support.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleFinishSetup = async () => {
        try {
            const { error } = await supabase
                .from('users')
                .update({ price: selectedPrice })
                .eq('username', username);

            if (error) throw error;
            onClose();
        } catch (error) {
            console.error('Error updating price:', error);
        }
    };

    return (
        <div className="onboard-overlay">
            <div className="onboard-popup">
                {currentScreen === 1 && (
                    <div className="onboard-content">
                        <h2>Welcome to Infield!</h2>
                        <h3 className="onboard-subheader">
                            Here's how Infield works:
                        </h3>
                        <div className="onboard-steps">
                            <div className="onboard-step">
                                <span className="step-number">1</span>
                                <p>Your fans follow your personal Infield link to ask you a paid question</p>
                            </div>
                            <div className="onboard-step">
                                <span className="step-number">2</span>
                                <p>We review each question to make sure it is appropriate</p>
                            </div>
                            <div className="onboard-step">
                                <span className="step-number">3</span>
                                <p>We email you when you have new questions, and you can log into Infield to view them</p>
                            </div>
                            <div className="onboard-step">
                                <span className="step-number">4</span>
                                <p>When you get a new question, you have 3 days to answer it. If you don't want to answer it, you can reject it or simply ignore it. You have no obligation to answer anything you don't want to!</p>
                            </div>
                        </div>
                        <button className="onboard-button" onClick={handleNextScreen}>
                            Got it!
                        </button>
                    </div>
                )}
                {currentScreen === 2 && (
                    <div className="onboard-content">
                        <h2>Set up your payments</h2>
                        <div className="onboard-steps">
                            <div className="step-subtitle">
                                Choose how you want us to pay our your earnings. Payouts occur on a bi-weekly basis.
                            </div>
                            
                            <div className="payment-options">
                                <label className={`payment-option ${paymentMethod === 'stripe' ? 'selected' : ''}`}>
                                    <input 
                                        type="radio" 
                                        name="paymentMethod" 
                                        value="stripe"
                                        onChange={(e) => setPaymentMethod(e.target.value)}
                                        checked={paymentMethod === 'stripe'}
                                    />
                                    <div className="payment-option-content">
                                        <div className="payment-option-title">Automatic secure direct deposits (recommended)</div>
                                    </div>
                                </label>

                                <label className={`payment-option ${paymentMethod === 'manual' ? 'selected' : ''}`}>
                                    <input 
                                        type="radio" 
                                        name="paymentMethod" 
                                        value="manual"
                                        onChange={(e) => setPaymentMethod(e.target.value)}
                                        checked={paymentMethod === 'manual'}
                                    />
                                    <div className="payment-option-content">
                                        <div className="payment-option-title">Manual payouts via payment app</div>
                                    </div>
                                </label>
                            </div>

                            {paymentMethod === 'stripe' && (
                                <>
                                    <div className="step-subtitle">
                                        Follow these steps to set up your payment account with <span style={{ color: '#635BFF', fontWeight: 'bold' }}>Stripe</span>. This will open in a new tab, and should take less than 3 minutes.
                                    </div>
                                    
                                    <button 
                                        className="stripe-connect-button" 
                                        onClick={handleStripeConnect}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? 'Loading...' : 'Connect payment account'}
                                    </button>

                                    <div className="instruction-step">
                                        <span className="step-number">1</span>
                                        <div className="instruction-text">
                                            Confirm your phone number with the website. Under <span className="instruction-section">Type of business</span>, select <span className="instruction-value">"Individual"</span>.
                                        </div>
                                    </div>
                                    <div className="instruction-step">
                                        <span className="step-number">2</span>
                                        <div className="instruction-text">
                                            Under <span className="instruction-section">Industry</span>, select <span className="instruction-value">"Consulting services"</span>.
                                            <br />
                                            Under <span className="instruction-section">Your website</span>, enter <span className="instruction-value">"https://www.infieldconnect.co/"</span>.
                                        </div>
                                    </div>
                                    <div className="instruction-step">
                                        <span className="step-number">3</span>
                                        <div className="instruction-text">
                                            Under <span className="instruction-section">Select an account for payouts</span>, choose your preferred bank or institution.
                                        </div>
                                    </div>
                                    <div className="instruction-step">
                                        <span className="step-number">4</span>
                                        <div className="instruction-text">
                                            Under <span className="instruction-section">Add public details for customers</span>, enter <span className="instruction-value">"INFIELDCONNECT.CO"</span> for the Statement descriptor.
                                        </div>
                                    </div>
                                    <div className="instruction-step">
                                        <span className="step-number">5</span>
                                        <div className="instruction-text">
                                            Under <span className="instruction-section">Review and submit</span>, wait for your information to finish verifying, review your entered information, and submit the form.
                                        </div>
                                    </div>
                                </>
                            )}

                            {paymentMethod === 'manual' && (
                                <div className="manual-payment-info">
                                    Email our team at <a href="mailto:info@infieldconnect.co">info@infieldconnect.co</a> with your preferred payment platform (Cash App, Paypal, etc.) and your account info.
                                </div>
                            )}
                        </div>
                        <button className="onboard-button" onClick={handleNextScreen}>
                            Continue setup
                        </button>
                    </div>
                )}
                {currentScreen === 3 && (
                    <div className="onboard-content">
                        <h2>Final setup steps</h2>
                        
                        <div className="onboard-steps">
                            <div className="step-title">Set your price per question</div>
                            <div className="step-subtitle">
                                This is what your fans will pay to ask you a short question. You keep 80% of each payment.
                            </div>
                            <select 
                                className="price-select"
                                value={selectedPrice}
                                onChange={(e) => setSelectedPrice(Number(e.target.value))}
                            >
                                {[...Array(196)].map((_, i) => {
                                    const price = i * 100 + 500;
                                    return (
                                        <option key={price} value={price}>
                                            ${(price/100).toFixed(2)}
                                        </option>
                                    );
                                })}
                            </select>

                            <div className="step-title">Share your link with your fans</div>
                            <div className="step-subtitle">
                                Let your social media fans know that you are accepting questions through Infield, and to follow this link to ask.
                            </div>
                            <div 
                                className={`copy-link ${showCopied ? 'copied' : ''}`}
                                onClick={handleCopyLink}
                            >
                                {userLink}
                                {showCopied && <div className="copied-tooltip">Copied!</div>}
                            </div>
                        </div>

                        <button className="onboard-button" onClick={handleFinishSetup}>
                            Finish setup
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default OnboardPopup;
