import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { TbMessages } from "react-icons/tb";
import { FaRegUser } from "react-icons/fa6";
import EditProfileTab from './EditProfileTab';
import user1 from '../running_profile.jpg';
import user2 from '../chris_profile.jpg';
import user3 from '../andrii.jpg';
import ReactCountryFlag from "react-country-flag";

// Supabase video URLs
const SUPABASE_VIDEO_URLS = {
    squat: 'https://kqlfidbnqabdhhibdwov.supabase.co/storage/v1/object/public/publicMedia//squat.mp4',
    video1: 'https://kqlfidbnqabdhhibdwov.supabase.co/storage/v1/object/public/publicMedia//vid1.mp4',
    video2: 'https://kqlfidbnqabdhhibdwov.supabase.co/storage/v1/object/public/publicMedia//vid2.mp4'
};

function DemoDashboard() {
    const [activeTab, setActiveTab] = useState('conversations');
    const [animationMode, setAnimationMode] = useState('static');
    const [popupSpeed, setPopupSpeed] = useState(1);
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);

    const mockUser = {
        firstName: 'Sarah',
        lastName: 'Ashley (You)',
        profile_pic: user1,
        city: 'New York',
        country: 'United States',
        age: 22,
        email: 'john.smith@example.com',
        dob: '2002-05-15'
    };

    const mockConversations = [
        {
            partnerId: '1',
            firstName: 'Christopher',
            lastName: 'Brake',
            username: 'physiobrake',
            profile_pic: user2,
            role: 'creator',
            sport: 'Wellness Creator',
            city: 'Courtenay',
            country: 'Canada',
            email: 'adam@example.com',
            approvedCount: 1,
            messages: [
                {
                    id: '1',
                    message_id: 'm1',
                    conversation_id: 'c1',
                    created_at: '2025-02-15T10:00:00',
                    sender_id: 'demo_user',
                    receiver_id: '1',
                    q_or_a: 'question',
                    payment_amount: 5,
                    status: 'completed',
                    text_or_video: 'text',
                    subject: 'Shin splits before half marathon',
                    message: "Hey! I've been training for a half marathon over the past 10 weeks and gradually increasing my mileage. I started feeling shin splints 2 weeks ago. I can still run, but the aching gets a little worse each time. My race is in four weeks, and I still want to run if possible. What rehab exercises should I do, and how often? I don't have access to weights, just a few resistance bands. Thanks Chrisopher!"
                },
                {
                    id: '2',
                    message_id: 'm2',
                    conversation_id: 'c1',
                    created_at: '2025-02-16T14:30:00',
                    sender_id: '1',
                    receiver_id: 'demo_user',
                    q_or_a: 'answer',
                    payment_amount: null,
                    status: 'completed',
                    text_or_video: 'video',
                    video_url: SUPABASE_VIDEO_URLS.video1,
                    subject: 'Re: Shin splits before half marathon',
                    message: 'Here are some tips to improve your defensive stance.'
                },
                {
                    id: '3',
                    message_id: 'm3',
                    conversation_id: 'c1',
                    created_at: '2025-02-17T10:00:00',
                    sender_id: 'demo_user',
                    receiver_id: '1',
                    q_or_a: 'question',
                    payment_amount: 5,
                    status: 'completed',
                    text_or_video: 'video',
                    video_url: SUPABASE_VIDEO_URLS.squat,
                    subject: 'Review my squat form',
                },
                {
                    id: '4',
                    message_id: 'm4',
                    conversation_id: 'c1',
                    created_at: '2025-02-18T14:30:00',
                    sender_id: '1',
                    receiver_id: 'demo_user',
                    q_or_a: 'answer',
                    payment_amount: null,
                    status: 'completed',
                    text_or_video: 'video',
                    video_url: SUPABASE_VIDEO_URLS.video2,
                    subject: 'Re: Review my squat form',
                }
            ]
        },
        {
            partnerId: '2',
            firstName: 'Andrii',
            lastName: 'Diachkov',
            username: 'vballadvice',
            profile_pic: user3,
            role: 'creator',
            sport: 'Basketball',
            city: 'Chicago',
            country: 'United States',
            email: 'mike@example.com',
            approvedCount: 1,
            messages: [
                {
                    id: '2',
                    message_id: 'm2',
                    conversation_id: 'c2',
                    created_at: '2024-03-14T15:30:00',
                    sender_id: '2',
                    receiver_id: 'demo_user',
                    q_or_a: 'question',
                    payment_amount: 30,
                    status: 'completed',
                    text_or_video: 'text',
                    subject: 'Basketball Shooting Form',
                    message: 'Could you give me advice on improving my jump shot?',
                }
            ]
        }
    ];

    const handleAnimationModeChange = (mode) => {
        setAnimationMode(mode);
        if (mode === 'static') {
            setCurrentMessageIndex(999); // Show all messages
            setIsAnimating(false);
        } else {
            setCurrentMessageIndex(0);
            setIsAnimating(true);
        }
    };

    const handleSpeedChange = (speed) => {
        const numSpeed = parseFloat(speed);
        if (numSpeed >= 0.5 && numSpeed <= 10) {
            setPopupSpeed(numSpeed);
            if (animationMode === 'animation') {
                setCurrentMessageIndex(0);
                setIsAnimating(true);
            }
        }
    };

    // Animation effect
    useEffect(() => {
        if (!isAnimating || animationMode !== 'animation') return;

        const physiobrakeConversation = mockConversations.find(conv => conv.username === 'physiobrake');
        if (!physiobrakeConversation) return;

        const timer = setTimeout(() => {
            if (currentMessageIndex < physiobrakeConversation.messages.length) {
                setCurrentMessageIndex(prev => prev + 1);
            } else {
                // Wait 3 seconds before restarting
                setTimeout(() => {
                    setCurrentMessageIndex(0);
                }, 3000);
            }
        }, popupSpeed * 1000);

        return () => clearTimeout(timer);
    }, [currentMessageIndex, isAnimating, animationMode, popupSpeed]);

    return (
        <div className="dashboard-page-format">
            <div className="dashboard-form">
                <div className='dashboard-header-container'>
                    <div className='dashboard-header-profile'>
                        <img 
                            className="dashboard-profile-picture" 
                            src={mockUser.profile_pic}
                            alt="Profile"
                        />
                        <div className='dashboard-name'>{mockUser.firstName} {mockUser.lastName}</div>
                    </div>
                </div>
                <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} className='dashboard-tab-title'>
                    <Tab eventKey="conversations"
                        title={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <TbMessages style={{marginRight: '8px' }}/>
                                <span className="tab-text">Conversations</span>
                            </div>
                        }
                    >
                        <DemoConversationsTab 
                            conversations={mockConversations} 
                            animationMode={animationMode}
                            currentMessageIndex={currentMessageIndex}
                        />
                    </Tab>
                    <Tab eventKey="profile"
                        title={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <FaRegUser style={{marginRight: '8px' }}/>
                                <span className="tab-text">Profile</span>
                            </div>
                        }
                    >
                        <div className="dashboard-tab-content">
                            <div className="dashboard-profile-title">Demo Profile</div>
                            <div className="animation-controls">
                                <div className="animation-mode-selector">
                                    <label>Animation Mode:</label>
                                    <select 
                                        value={animationMode} 
                                        onChange={(e) => handleAnimationModeChange(e.target.value)}
                                    >
                                        <option value="static">Display all messages</option>
                                        <option value="animation">Display one at a time</option>
                                    </select>
                                </div>
                                {animationMode === 'animation' && (
                                    <div className="speed-control">
                                        <label>Message popup speed (seconds):</label>
                                        <input 
                                            type="number" 
                                            min="0.5" 
                                            max="10" 
                                            step="0.1"
                                            value={popupSpeed}
                                            onChange={(e) => handleSpeedChange(e.target.value)}
                                        />
                                    </div>
                                )}
                            </div>
                            <p>This is a simulated profile page for demonstration purposes.</p>
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
}

function DemoConversationsTab({ conversations, animationMode, currentMessageIndex }) {
    const [selectedConversation, setSelectedConversation] = useState(null);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
        });
    };

    const getVideoUrl = (path) => {
        return path;
    };

    const isMessageVisible = (index) => {
        if (animationMode === 'static') {
            return true;
        }
        return index < currentMessageIndex;
    };

    const handleVideoClick = (event) => {
        const video = event.currentTarget.previousSibling;
        video.play();
        event.currentTarget.style.display = 'none';
        video.classList.add('playing');
    };

    const handleVideoPause = (event) => {
        const video = event.target;
        const overlay = video.nextElementSibling;
        overlay.style.display = 'flex';
        video.classList.remove('playing');
    };

    return (
        <div className="conversations-container">
            <div className={`conversations-list ${selectedConversation ? 'mobile-hidden' : ''}`}>
                {conversations.map((conv) => (
                    <div
                        key={conv.partnerId}
                        className={`conversation-entry ${selectedConversation?.partnerId === conv.partnerId ? 'selected' : ''}`}
                        onClick={() => setSelectedConversation(conv)}
                    >
                        <img
                            src={conv.profile_pic}
                            alt="Profile"
                            className="conversation-profile-pic"
                        />
                        <div className="conversation-user-info">
                            <div className="conversation-name-container">
                                <span className="conversation-name">{conv.firstName} {conv.lastName}</span>
                                {conv.username && <span className="conversation-username">@{conv.username}</span>}
                            </div>
                        </div>
                        {conv.approvedCount > 0 && (
                            <div className="conversation-badge">{conv.approvedCount}</div>
                        )}
                    </div>
                ))}
            </div>
            <div className={`messages-container ${!selectedConversation ? 'mobile-hidden' : ''}`}>
                {selectedConversation ? (
                    <>
                        <div className="messages-header mobile-only">
                            <span onClick={() => setSelectedConversation(null)}><b>Back</b></span>
                        </div>
                        <div className="user-info-banner">
                            {`${selectedConversation.firstName} is a ${selectedConversation.sport} from ${selectedConversation.city}, ${selectedConversation.country} `}
                            <ReactCountryFlag 
                                countryCode={selectedConversation.country === 'United States' ? 'US' : 
                                           selectedConversation.country === 'Canada' ? 'CA' : ''} 
                                svg
                                style={{
                                    width: '1.2em',
                                    height: '1.2em',
                                    marginLeft: '4px'
                                }}
                            />
                        </div>
                        <div className="messages-list">
                            {selectedConversation.messages.map((message, index) => (
                                <div 
                                    key={message.id} 
                                    className={`message-group ${!isMessageVisible(index) ? 'message-hidden' : ''}`}
                                >
                                    <div className="message-date">{formatDate(message.created_at)}</div>
                                    <div className={`message-bubble ${
                                        message.sender_id === 'demo_user' ? 'own-message' : 
                                        message.status === 'approved' ? 'approved-message' : 'other-message'
                                    }`}>
                                        <div className="message-subject-container">
                                            <div className="message-subject">{message.subject}</div>
                                        </div>
                                        <div className="message-content">
                                            {message.text_or_video === 'video' ? (
                                                <div className="custom-video-container">
                                                    <video 
                                                        src={getVideoUrl(message.video_url)} 
                                                        className='conversation-video'
                                                        playsInline
                                                        preload="metadata"
                                                        onPause={handleVideoPause}
                                                        controls
                                                    />
                                                    <div 
                                                        className="play-button-overlay"
                                                        onClick={handleVideoClick}
                                                    >
                                                        <div className="play-icon">▶</div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="message-text">{message.message}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                ) : (
                    <div className="conversations-empty">Select a conversation to view messages</div>
                )}
            </div>
        </div>
    );
}

export default DemoDashboard; 