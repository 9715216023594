import React, { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { FaSwimmer } from "react-icons/fa";
import { FaPersonRunning } from "react-icons/fa6";
import { TbMessages } from "react-icons/tb";
import justice from '../justice.jpg';
import harry from '../harry.jpg';
import justiceheadshot from '../justice_headshot.jpg';
import harryheadshot from '../harry-headshot.jpg';
function AboutPage() {
    useEffect(() => {
        // Set CSS variable for the first visual's icons
        document.querySelectorAll('.about-pitch-visual:nth-of-type(1) .about-pitch-icon-athlete-container .about-pitch-icon-athlete-color, .about-pitch-visual:nth-of-type(1) .about-pitch-icon-athlete-container .about-pitch-icon-athlete-gray').forEach((icon, index) => {
            icon.style.setProperty('--n', index + 1)
        })

        // Set CSS variable for the second visual's icons
        document.querySelectorAll('.about-pitch-visual:nth-of-type(2) .about-pitch-icon-athlete-container .about-pitch-icon-athlete-color, .about-pitch-visual:nth-of-type(2) .about-pitch-icon-athlete-container .about-pitch-icon-athlete-gray').forEach((icon, index) => {
            icon.style.setProperty('--n', index + 1)
        })
    }, [])

    return (
        <div>
            <div className='about-wrapper'>
                <h1 className='about-header'>Why we made Infield</h1>
                <div className='about-row ' sm={1} lg={2}>
                    <div className='about-col-visuals'>
                        <div className='about-pitch-visual'>
                            <div className='inner'>
                                <h1 className='about-pitch-title'>
                                    <span className='text-color-tertiary'><b>1 in 4 Olympic hopefuls</b></span> depend on another job to support themselves.
                                </h1>
                                <div className='about-pitch-icon-athlete-container'>
                                    {[...Array(25)].map((_, i) => (
                                        <FaSwimmer key={`white-${i}`} className='about-pitch-icon-athlete-color' />
                                    ))}
                                    {[...Array(75)].map((_, i) => (
                                        <FaSwimmer key={`yellow-${i}`} className='about-pitch-icon-athlete-gray' />
                                    ))}
                                </div>
                                <div className='about-pitch-source'>
                                    Commission on the State of US Olympics & Paralympics
                                </div>
                            </div>
                        </div>
                        <div className='about-pitch-visual'>
                            <div className='inner'>
                                <h1 className='about-pitch-title'>
                                    <span className='text-color-tertiary'><b>Only 12% of Olympic hopefuls</b></span> ever receive sponsorships or deals.
                                </h1>
                                <div className='about-pitch-icon-athlete-container'>
                                    {[...Array(12)].map((_, i) => (
                                        <FaPersonRunning key={`white-${i}`} className='about-pitch-icon-athlete-color' />
                                    ))}
                                    {[...Array(88)].map((_, i) => (
                                        <FaPersonRunning key={`yellow-${i}`} className='about-pitch-icon-athlete-gray' />
                                    ))}
                                </div>
                                <div className='about-pitch-source'>
                                Commission on the State of US Olympics & Paralympics
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='about-col-text'>
                        <div className='about-text-content'>
                            <h3>Elite athletes spend years mastering training, nutrition, and whatever else their sport demands. </h3>
                            <p>
                                Despite being experts in 
                                their craft, their financial rewards often do not match the level of their dedication. While some athletes
                                 may secure million-dollar endorsements, the majority of athletes struggle to gain sponsorships 
                                or a steady income from their sport.
                            </p>
                            <p>
                            Our platform gives these talented athletes a new way to earn money while sharing their knowledge and expertise directly with fans. By answering sports-related questions, athletes can connect with supporters who value their hard work, helping to fund their journey toward success while providing fans with exclusive insights from the best in the field.
                            </p>
                        </div>
                        <div className='home-pitch-button-container'>
                            <Button className='home-pitch-button' href='/join'>
                                <FaPersonRunning className='home-pitch-button-icon' />
                                Join Infield for free
                            </Button>
                        </div>
                    </div>
                </div>
                <h1 className='about-header'>Our story</h1>
                <div className='about-row-images'>
                    <div className='about-image-container'>
                        <img src={justice} alt="Justice" className='about-image' />
                        <div className='about-headshot-caption-container'>
                            <img src={justiceheadshot} alt="Justice Headshot" className='about-headshot-image' />
                            <div className='about-image-caption'>Justice Dixon</div>
                            <div className='about-image-subcaption'>Princeton Track & Field</div>
                            <div className='about-image-subcaption'>Virginia Track & Field</div>

                        </div>
                    </div>
                    <div className='about-image-container'>
                        <img src={harry} alt="Harry" className='about-image' />
                        <div className='about-headshot-caption-container'>
                            <img src={harryheadshot} alt="Harry Headshot" className='about-headshot-image' />
                            <div className='about-image-caption'>Harry Lord</div>
                            <div className='about-image-subcaption'>Princeton Track & Field</div>
                            <div className='about-image-subcaption'>Duke Track & Field</div>
                        </div>
                    </div>
                </div>
                <div className='about-row'>
                    <div className='about-col-text-founders'>
                        <div className='about-text-content'>
                            <h3>We're Justice and Harry</h3>
                            <p>
                                Two former NCAA D1 track & field athletes from the US and UK.
                            </p>
                            <p>
                                Throughout our athletic careers, we grappled with every challenge you can imagine—cramps, nutrition, strength, recovery, mental resilience, and performance psychology. The guidance we needed was hard to find. Online advice was often too generic, and the true expertise got lost in the noise.
                            </p>
                            <p>
                                We found solutions after personally connecting with mentors who truly listened to our individual needs. Often, it took months or even years to find the right support. Along the way, we found a wealth of wisdom in athletes who were looked up to by thousands—but not paid.
                            </p>
                            <p>
                                <b>We made Infield to change that.</b> Our mission is twofold: to give people direct, one-on-one access to world-class expertise without the noise, and to create a platform where athletes are rightfully rewarded for their invaluable knowledge.
                            </p>
                        </div>
                        <div className='home-pitch-button-container'>
                            <Button className='home-pitch-button' href='/contact'>
                                <TbMessages className='home-pitch-button-icon' />
                                Get in touch with us
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutPage