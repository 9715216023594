import React, { useEffect } from 'react';

const MouseflowTracker = () => {
  useEffect(() => {
    // Initialize Mouseflow
    if (window._mfq) {
      window._mfq.push(['startNewSession']);
    }
  }, []);

  return null;
};

export default MouseflowTracker; 