import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { supabase } from '../SupabaseClient';
import ConfirmationAlert from './ConfirmationAlert';

function HomeCTASection() {
    const [showAlert, setShowAlert] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        message: ''
    });

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Insert data into Supabase table
        const { error } = await supabase
            .from('contact_form')
            .insert([{
                first_name: formData.firstName,
                last_name: formData.lastName,
                email: formData.email,
                content: formData.message
            }]);

        if (error) {
            console.error('Error!', error.message);
        } else {
            setShowAlert(true);
            // Clear form
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                message: ''
            });
        }
    };

    return (
        <div className='home-cta-container'>
            {showAlert && (
                <ConfirmationAlert 
                    message="Thank you for your message!" 
                    onClose={() => setShowAlert(false)} 
                />
            )}
            <div className='home-cta-row'>
                <div className='home-cta-col'>
                    <h2 className='home-cta-title'>Ready to make real progress on your goals?</h2>
                    <p className='home-cta-text'>
                        Choose an athlete and get advice ASAP
                    </p>
                    <Button className='home-cta-button' href="/ask">
                        Ask something
                    </Button>
                </div>

                {/* <div className='home-cta-col'>
                    <h2 className='home-cta-title-small'>Need something? Get in touch with us</h2>
                    <form className='home-cta-form' onSubmit={handleSubmit}>
                        <div className='home-cta-form-row'>
                            <input
                                type="text"
                                name="firstName"
                                placeholder="First Name"
                                className='home-cta-input'
                                value={formData.firstName}
                                onChange={handleInputChange}
                                required
                            />
                            <input
                                type="text"
                                name="lastName"
                                placeholder="Last Name"
                                className='home-cta-input'
                                value={formData.lastName}
                                onChange={handleInputChange}
                                required
                            />
                            <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                className='home-cta-input'
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <textarea
                            name="message"
                            placeholder="Message"
                            className='home-cta-textarea'
                            value={formData.message}
                            onChange={handleInputChange}
                            required
                        />
                        <Button type="submit" className='home-cta-button'>
                            Send Message
                        </Button>
                    </form>
                </div> */}
            </div>
        </div>
    );
}

export default HomeCTASection;
